import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { properties } from "../../content/Properties"
import InnerPropertyPage from "../../components/molecules/InnerPropertyPage"

const slug = `32-midlow-rd-elizabeth-downs-sa-5113`

const post = properties.find(post => post.slug === slug) // Find the blog post by slug

const MidlowRdElizabethDownsSa5113 = () => (
  <Layout>
    <Seo
      title="Discover Affordable NDIS-Supported SDA Housing in Elizabeth Downs"
      description=" Looking for affordable and accessible housing in Elizabeth Downs? Check out our NDIS-supported property, designed for those with special needs. Take benefit of this opportunity!"
    />

    <InnerPropertyPage post={post} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default MidlowRdElizabethDownsSa5113
